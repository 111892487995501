import "./ThanksPage.scss";
import { useLocation } from "react-router-dom";

const ThanksPage = () => {
  const { state } = useLocation();
  const order = state?.order;

  if (!order) {
    return <div>Ошибка: заказ не найден</div>;
  }

  const dateObj = new Date(order.date);
  const dateStr = dateObj.toLocaleDateString("ru-RU");
  const timeStr = dateObj.toLocaleTimeString("ru-RU", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const [region, city, addressDetails] = order.address.split(",");

  return (
    <div className="thanks-page">
      <h2 className="thanks-page__top">Вы забронировали</h2>
      <h3 className="thanks-page__order">
        Заказ №{String(order.orderNumber).padStart(3, "0")}
      </h3>

      <div className="thanks-page__receipt">
        <p>
          {order.type} уборка {order.group.toLowerCase()}
        </p>
        <p>{order.room_count} – комнатная</p>
        <p>{order.bathroom_count} – Сан/Узел</p>

        {order.additional_services?.length > 0 && (
          <>
            <p>
              <strong>Дополнительные услуги</strong>
            </p>
            {order.additional_services.map(service => (
              <p key={service.id}>
                {service.name} – ({service.count})
              </p>
            ))}
          </>
        )}

        <p>
          Дата уборки: {dateStr} в {timeStr}
        </p>

        <p>
          <strong>Адрес Заказа</strong>
        </p>
        <p>регион – {region || "–"}</p>
        <p>город – {city || "–"}</p>
        <p>улица – {addressDetails || "–"}</p>
        <p>Наличие домофона – {order.intercom || "–"}</p>
        <p>Пожелания по заказу – ({order.comment || "-----"})</p>

        <p>
          <strong>Стоимость заказа</strong>
        </p>
        <p>{order.price.toLocaleString()} руб</p>
      </div>
    </div>
  );
};

export default ThanksPage;
