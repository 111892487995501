// import Form from './form/Form'
// import Regulations from './regulations/Regulations'
// import Tab from './tab/Tab'
import Benefist from "./benefist/Benefist";
// import SliderTab from './slider-tab/SliderTab'
import { Navigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import "./Landing.scss";
import "./serviceList/ServiceList.scss";
import { Link } from "react-router-dom";
import Feedback from "./feedback/Feedback";
import Questions from "./questions/Questions";
import QuestionsOrder from "./questionsOrder/questionsOrder";
import Sales from "./sales/Sales";
import BenefistSale from "./benefistSale/BenefistSale";
// import { motion } from "framer-motion";
// import { UpAnimation, OpacityAnimation } from "../../../animations";
import OurEquipment from "../../../components/our-equipment/OurEquipment";

const Landing = () => {
  const tabs = [
    {
      title: "Поддерживающая",
      content: "Поддерживающая уборка от 2900 ₽",
    },
    {
      title: "Генеральная",
      content: "Генеральная уборка от 7400 ₽",
    },
    {
      title: "После ремонта",
      content: "После ремонта уборка от 8900 ₽",
    },
  ];
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = index => {
    setActiveTab(index);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const promoRef = useRef(null);

  const copyToClipboard = () => {
    const promoText = "First";
    navigator.clipboard.writeText(promoText);
    promoRef.current.innerText = "Скопировано!";
  };

  if (localStorage.getItem("token")) {
    return <Navigate to="/main" />;
  }

  return (
    <section style={{ overflow: "hidden" }}>
      <div className="landing__main">
        <div className="landing__container service-list-container">
          <ul className="service-list">
            <li>
              <Link to="/uslugi/uborka-kvartir">
                <div>Уборка Квартиры</div>
              </Link>
            </li>
            <li>
              <Link to="/uslugi/uborka-pomesheniy/office/">
                <div>Уборка офиса</div>
              </Link>
            </li>
            <li>
              <Link to="/uslugi/uborka-pomesheniy/warehouse/">
                <div>Склад</div>
              </Link>
            </li>
            <li>
              <Link to="/cleaning">
                <div>Химчистка</div>
              </Link>
            </li>
          </ul>
        </div>
        <div className="landing__container ">
          <ul className="service-list">
            {tabs.map((tab, index) => (
              <li key={index} onClick={() => handleTabClick(index)}>
                <div
                  className={`${
                    activeTab === index ? "service-list__active" : ""
                  }`}>
                  {tab.title}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="landing__wrapper-form">
        <div className="landing__container landing__container-tab">
          <div></div>

          {activeTab !== -1 ? (
            <div className="landing__main-form landing__main-form-tab">
              <div className="landing__form">
                <div className="landing__form-title">
                  {tabs[activeTab].content}
                </div>
                <Link
                  to={"/calculator"}
                  className="landing__form-button"
                  style={{
                    textDecoration: "none",
                  }}>
                  Заказать уборку квартиры
                </Link>
                <div className="landing__form-border">
                  <a
                    href="https://yandex.ru/business/widget/request/company/157082417183"
                    target="_blank"
                    className="landing__form-button"
                    style={{
                      textDecoration: "none",
                    }}
                    rel="noreferrer">
                    Перезвонить мне
                  </a>
                </div>
                <div className="landing__promo">
                  <div
                    className="promo"
                    ref={promoRef}
                    onClick={copyToClipboard}>
                    First
                  </div>
                  <div className="title">
                    <strong>Скидка 8%</strong> на первый заказ{" "}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <div className="landing__main">
        <BenefistSale />
        <Benefist />
        <QuestionsOrder />
        <Feedback />

        <OurEquipment />
        <Sales />
      </div>
      <div className="benefits__wrapper">
        <div className="landing__container">
          <Questions />
        </div>
      </div>
    </section>
  );
};

export default Landing;
